#myInput{
    min-width: 400px;
    padding: 12px;
    border-radius: 15px;
    border-color: #54545498;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

#bodyBox{
    width: 70%;
    margin: 0 auto;
    margin-bottom: 150px;
   
    

}

table{
    width: 100%;
    text-align: left;
    
    border-collapse: collapse;
   
    
}

th, td{
    width: 100px;
    border: 0.5px solid rgba(128, 128, 128, 0.582);
    padding: 10px;
    
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

td a{
    color: #257166;
}
#headerIcon svg{
    color: #257166 !important;
  
}