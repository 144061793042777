
  
  .inline{
    display: inline-block;
    margin:10px;
    min-height: 200px;
    min-width:280px;
      
    padding: 20px;
    font-size: 50px;
    color: #2E8A7C;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    text-align: center;
   
  }

  .inline h5{
    color: #545454;
    font-size: medium;
    font-weight: 500;
    color: #545454;
  }

  .inline h6{
    font-weight: 300;
    text-align: center;
    font-size: 20px;
    
  }

  .inline h6:hover{
    cursor: pointer;
  
  }

  #dateBox{
    margin:10px;
    margin-bottom: 20px;
    min-height:150px;
    width:880px;   
    padding: 10px;
    font-size: 20px;
    font-weight: 600!important;
    color: #2E8A7C;
    font-weight: 400;
    align-items: center;
    justify-content: center;
    text-align: center;   
    margin: 0 auto;
    display:flex ;

  }

 

  .updateForm h1{
    color: #2E8A7C;
  }

  #formContainer{
    background-color: white;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 85%;
    margin: 0 auto;
    box-shadow: 10px 10px 8px gray;
    border-radius: 25px;
  }

  .labelBox{
    max-width: 250px;
    display: inline-block;
  }

  .labelBox p{
    margin-left: 20px!important;
  }

  #formContainer .MuiFormControl-root{
    margin-right: 10px !important;
    margin-left: 10px!important;
  }