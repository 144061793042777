.tabs{
    font-family:arial;
    margin:0 auto;
  
    text-align:center;
   
  
  }
  
  img{
    width:300px;
  }
  
 

  .displayTab{
      background-color: #d9d9d95b;
      
      border-radius: 25px;
      border-bottom-right-radius: 25px;
      display: inline-block;
      width: 90%;
      margin-left:10px;
      margin-right:10px;
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
      box-shadow: 10px 10px 8px #888888;
      padding: 12px;
      height: auto;

  }

  .tab-content{
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    box-shadow: 0px 7px 5px rgb(128, 128, 128);
  }

   #inputBox, #demo-simple-select{
   
    text-align: center;
    
  }

  #inputBox{
    margin-bottom: 3px!important;
  }

  #demo-simple-select-label{
    margin-bottom: 3px !important;
    margin-top: 3px !important;
  }

 #bodyBoxAdmin{
  width: 70%;
  margin: 0 auto;
 

 }

 .updateForm{
  background-color: #EFEFEF;
  box-shadow: 10px 10px 8px #888888;
  width:100%;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 40px;
  margin-top: 20px;
  margin-bottom: 100px;
  border-radius: 25px;

}

.create_new{
  
  box-shadow: 10px 10px 8px #888888;
  width:100%;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 40px;
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 25px;
 
}

.buttons{
  margin-top: 20px !important;
}

.tab-buttons button{
    
    
  font-size:20px;
  background:transparent;
  border:none;
  outline:none;
  padding:10px 20px ;
  cursor:pointer;
  color:rgba(0,0,0,.4);
  transition:all ease-in-out .2s;
  border-bottom:2px solid transparent;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top: solid 1px #3EBDA8;
  border-left: solid 1px #3EBDA8;
  border-right: solid 1px #3EBDA8;
  width: 50%;
  
  
}

.tab-buttons-super button{
    
    
  font-size:20px;
  background:transparent;
  border:none;
  outline:none;
  padding:10px 20px ;
  cursor:pointer;
  color:rgba(0,0,0,.4);
  transition:all ease-in-out .2s;
  border-bottom:2px solid transparent;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top: solid 1px #3EBDA8;
  border-left: solid 1px #3EBDA8;
  border-right: solid 1px #3EBDA8;
  width: 33.3%;
  
  
}
.tab-buttons .active, .tab-buttons-super .active{

 background-color: #3EBDA8;
  color:white;
  box-shadow:  5px 0px 7px rgb(128, 128, 128);

}



#tabs{
    width: 100%;
    
}