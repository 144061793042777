.App {
  text-align: center;
  height: auto;
 
 
  
}




#toast{
  color:	#535353;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.css-zxdg2z{
  padding-left: 0!important;
  padding-right: 0!important;
  padding-top: 50px !important;
}

.isnlogo {
  width: 16%;
  padding-bottom: 5px;;
}

.MuiSvgIcon-root {

  color: white;
  background-color: transparent !important;
 
}

#lockoutIcon {
  color: white;
  background-color: #257166 !important;
}

#avataBox,  .makeStyles-avatar-64, .MuiAvatar-colorDefault{
  background-color: #257166 !important; 
}

#bodyBoxHome{
  width: 70%!important;
  margin: 0 auto;
 margin-bottom: 20px; 
 line-height: 33px;

}

.MuiStepper-horizontal{

  background-color: #3EBDA8 !important;
  border-radius: 40px;
  
}

.MuiStepIcon-root.MuiStepIcon-active{
 color: #545454 !important;
}

.react-pdf_message, .react-pdf_message--error{

  font-size: 50;
}

#iframe{
  margin-top: 50px;
}

#pageNumberP{
  font-size: 14px !important;
}

#my_wrapper{
  overflow: hidden; /* will contain if #first is longer than #second */
  margin-bottom: 70px;
  margin-top: 50px;
  
}

#myTable{

  background-color: rgba(255, 255, 255, 0.678);
}

#deleteBtn{
  overflow: hidden; 
  float:right;
}

#looksgood{
  float:right;
}

.makeStyles-drawerOpen-9, .makeStyles-drawerOpen-9, .makeStyles-drawerOpen-13, .makeStyles-drawerClose-14, .css-12i7wg6-MuiPaper-root-MuiDrawer-paper{
  color:white !important;
  background-color: #4d4d4db2!important;

 
}

.hidden{
  display: none !important;
}

.iconMenu{
  display: none !important;
}
/*#iconMenu, #drawerBar, #mainCont, #logoutBtn{
  display: none;

}*/

.makeStyles-content-16{
  padding: 0!important;
}

#filewrappers {
  width: 100%;
  /*border: 1px solid black;*/
  overflow: hidden; /* will contain if #first is longer than #second */
  margin: 0 auto;
  border-top: solid 1px rgba(128, 128, 128, 0.637);
  margin-top: 60px;
  display: none;
}
.blocks{
 
  display: inline-table;
  margin: 10px;
}

.divWrapper{
  width: 100%;
  align-content: flex-start;
  justify-content: space-between;

}
.blocksL{
 
  display: inline-block;
  margin: 10px;
  min-width: 200px;
}

#btnLedger button {
  margin-top: 30px !important;
}

.MuiFormControl-root{
  vertical-align: sub !important;
  
}

#SupplierName{
  padding-top: 22px !important;
  margin-bottom: -2px;
}


#retrieveBtn{
  margin-top: 20px !important;
}

.css-17vdz66{
  margin: 0 auto !important;
}



.myMarginU{
  margin-left: 260px !important;
  transition: margin-left ease-in 700ms;
  transition: margin-left ease-out 700ms;
  opacity: 1;
  transform: scale(1);
}

.myMargin{
  margin-left: 300px !important;
  transition: margin-left ease-in 700ms;
  transition: margin-left ease-out 700ms;
  opacity: 1;
  transform: scale(1);
}

/*.MuiList-padding {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}*/

.MuiListItem-root{
  padding-top: 11px!important;
  padding-bottom: 11px!important;
 
}



.divWrapperL{
  width: 100%;
  align-content: flex-start;
  justify-content: space-between;

}

#firstdoc {
  /*width: 100%;*/
  float:left; /* add this */
  margin-right: 7px;
  margin-top: 10px;
  margin-left: 15px;
 /* border: 1px solid red;*/
}
#seconddoc {
 /* border: 1px solid green;*/
  overflow: hidden; /* if you don't want #second to wrap below #first */
  margin-left: 7px;
}

#firstdoc canvas{
  width: 100% !important;
  flex:3;
  position: relative; 
  
}

.__dbk__dragger.Horizontal{
  height: 900px;
  padding: 0;
  max-width: 1px!important;
  
  
}

#drop{
 
  background-color: rgba(255, 255, 255, 0.253) !important;
}



.Dark{
  background-color: #545454 !important;
}

#divInp{
 float: left !important;
 margin: 10px;
margin-left: 20px;

 

}

.makeStyles-avatar-46, .makeStyles-avatar-54{
  background-color: #2E8A7C!important;
}

#userTab{

  font-size: small;
  float: inline-end !important;
  width: 100%;
  
}

h6{
  width: 100%;
  text-align: end;
}

.makeStyles-avatar-15, .makeStyles-avatar-22{
  background-color: white!important;
}

#pageNumberP{
  display: none;
}

.react-pdf__message--error{

  display: none !important;
}


.MuiAccordionDetails-root{
  margin: 0 auto !important;
  display: block !important;
}

.MuiTextField-root div, .MuiTextField-root label{
  margin-top: 10px;
 

}

.isnlogo:hover, #logoutBtn:hover{
  cursor: pointer;
}

#logoutBtn{
  margin-left: 20px;
 
}

/*#logoutBtn, #iconMenu, #drawerBar{
  display: none;
}*/

.active{
  background-color: #2E8A7C!important;
  box-shadow: 5px 5px 4px black;
  color: white !important;

}
#blockBox {
  
  display:flex !important;
  justify-content: center !important;

}

.MuiDrawer-docked{
  background-color: transparent !important;
}

.MuiDataGrid-root, .MuiDataGrid-main{

  font-size: 13px !important;

}

.MuiDataGrid-columnsContainer, .MuiDataGrid-footerContainer{
  line-height: 40px !important;
  background-color: #D9D9D9;
  color: #545454;
}

h3 {
  width: 100%;
 font-weight: 600 !important;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 35px;
  color: #257166;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  margin-bottom: 10px;
  margin: 0 auto;
  

}



li{
width:100%;
list-style: none;
font-size: larger;
padding-top: 10px;
padding-bottom: 10px;
letter-spacing: 0.5px;
border-bottom: solid 0.5px #257166;

}

.makeStyles-avatar-19{
  background-color: transparent !important;
}

li a{

  color: #545454;
  text-decoration: none;
}

tr a:hover{
  color: #2e8a7c5e;
}

tbody>tr:hover{

  background-color: #3ebda84f;
}

.primary{
  color:#2E8A7C !important;
}

.MuiButton-root{
  background-color: #2E8A7C!important;
  color:white !important;
  padding: 5px!important;
  padding-right:12px !important;
  padding-left: 12px !important; 
  margin: 5px !important;
  min-width: 90px !important;
}

#loading-spinner {
  display: inline;
  position: relative;
  z-index: 999999;
}

#loading-spinner .spin-icon {
  margin: auto;
  width: 80px;
  height: 80px;

  border: solid 3px transparent;
  border-top-color:  #2E8A7C;
  border-left-color: #3EBDA8;
  border-radius: 80px;

  -webkit-animation: loading-spinner 500ms linear infinite;
  -moz-animation:    loading-spinner 500ms linear infinite;
  -ms-animation:     loading-spinner 500ms linear infinite;
  -o-animation:      loading-spinner 500ms linear infinite;
  animation:         loading-spinner 500ms linear infinite;
}

@-webkit-keyframes loading-spinner {
  0%   { -webkit-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes loading-spinner {
  0%   { -moz-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}

@-o-keyframes loading-spinner {
  0%   { -o-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}

@-ms-keyframes loading-spinner {
  0%   { -ms-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -ms-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes loading-spinner {
  0%   { transform: rotate(0deg);   transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #4d4d4d;
}

.App-link {
  color: #2E8A7C;
}

.container{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.containertable{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.rowC{display:flex; flex-direction:row;}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.MuiTypography-colorPrimary{

  color: #2E8A7C !important;
}

#spaceBox{
  margin-bottom: 80px;
}

.inlineBoxes{
  display: inline-block;
  margin: 20px;
}

.docDetailBox{
  background-color: #84848423;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 5px 5px 4px rgba(61, 61, 61, 0.747);
  margin-top: 20px;
}


#dataTable{
  overflow: auto!important;
}

.authorized{
  background-color: orange!important;
}

.paid{
  background-color: green!important;
}

.clean{

}
/*
.MuiBox-root{
  background-image: linear-gradient(to right, rgba(84, 253, 202, 0.411) , rgba(221, 218, 218, 0.699))!important;
}*/

.glass {
  background: rgba(216, 216, 216, 0.342);
 
  border-radius: 2rem;
  z-index: 2; 
  backdrop-filter: blur(2rem);
  height: auto;
  position: relative;
  box-shadow: 4px 4px 3px #31313169;
}

.glass_padd {
  background: rgba(216, 216, 216, 0.342);
  border-radius: 2rem;
  z-index: 2;
  backdrop-filter: blur(2rem);
  height: auto;
  position: relative;
  box-shadow: 4px 4px 3px #31313169;
  padding: 15px;
  margin-bottom: 15px;
}

.glass_no_bg, #bodyBox, #containerBox{

  z-index: 2;
  position: relative;
}

.circle1,
.circle2 {
  background: white;
  background: linear-gradient(
    to right bottom,
    #ffffff6c,
    #fafafa5b
  );
  height: 25rem;
  width: 25rem;

  border-radius: 50%;
  position: fixed;
 
}

.circle1 {
  top: 5%;
  right: 15%;
}
.circle2 {
  bottom: 5%;
  left: 10%;
}

.tab-buttons .active, .tab-buttons-super .active{

  background-color: #3EBDA8;
   color:white;
   box-shadow:  5px 0px 7px rgb(128, 128, 128);
 
 }
 
 
 
 #tabs{
     width: 100%;
     
 }

 .tab-buttons-super button{
    
    
  font-size:20px;
  background:transparent;
  border:none;
  outline:none;
  padding:10px 20px ;
  cursor:pointer;
  color:rgba(0,0,0,.4);
  transition:all ease-in-out .2s;
  border-bottom:2px solid transparent;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top: solid 1px #3EBDA8;
  border-left: solid 1px #3EBDA8;
  border-right: solid 1px #3EBDA8;
  width: 33.3%;
  
  
}

.displayTab{
  background-color: #d9d9d95b;
  
  border-radius: 25px;
  border-bottom-right-radius: 25px;
  display: inline-block;
  width: 80%;
  margin-left:10px;
  margin-right:10px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  box-shadow: 10px 10px 8px #888888;
  padding: 12px;
  height: auto;

}

.tab-content{
border-bottom-left-radius: 25px;
border-bottom-right-radius: 25px;
box-shadow: 0px 7px 5px rgb(128, 128, 128);

}

.tabs{
  font-family:arial;
  margin:0 auto;

  text-align:center;
 

}

#Tabs_box{
  width:90%;
  margin:0 auto;
}

.instructions{

  font-size: smaller!important;
}

#retrieveBtn, #tenants_btn{
  color: #2E8A7C!important;
  background-color: transparent!important;
  
  box-shadow: none;
  border: solid 1px #2E8A7C;

}

#retrieveBtn:hover, #tenants_btn:hover{
  font-weight: 600;
 
}

.xero_btn_box:hover{
  box-shadow: 10px 10px 8px #888888;
}

.centralized_box{

  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

#demo-simple-select{
  min-width: 150px;
 
}

#tenants_btn{
  margin-right: 10px;
}

.xero_btn_box{
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: rgba(209, 208, 208, 0.616);
  border-radius: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%!important;
  position: relative;
}

#save_tenant_btn{
  margin-left: 20px!important;
}

.card_btns{

  width: 100% !important;
  height: 90px;
  margin-left: 5px;
  margin-right:5px;
  border-radius: 25px!important;
  box-shadow: 4px 4px 3px #31313169!important;

 
}

.card_box{
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.card_icons svg{
  min-width: 50px!important;
  margin-top: 10px!important;
  margin-right: 10px;
}

.div-root-47{
  min-height: 0!important;
}

.icon_btn svg{
 padding-top: 5px!important;
}

#dateBox p{
  font-size: large;
  font-weight: bold;
}

.number_order{
  color: rgba(255, 255, 255, 0.623);
  font-size: 70px!important;
  font-weight: bolder!important;
  text-align: start;
  padding-left: 30px;
  align-items: center;
  position: absolute;
  top: 8px;
  left: 16px;
  font-size: 18px;
}



.form_box{

    display: grid;
   
}

.detailBtn{
  border: none !important;
}

.field select{

width: 50%!important;
 margin: 0 auto;
}



.prompt{
  height: 40px;
  font-size: medium;
  width: 100% !important;
}

#addBtn{
  height: 40px;
 border: none;
 font-size: medium;
 border-radius: 4px;
width: 200px !important;
min-width: none!important;
margin-top: 20px;
}

#addCompanyForm input, #addCompanyForm select, #inputBoxCompany select{
  padding:7px !important;
  width: 350px !important;
  height: 30px !important;
}

#inputBoxCompany{
  padding: 0!important;
  
}

#addCompanyForm div{
  width: 350px !important;
  margin: 0 auto;
  margin-top: 10px;
}

#addCompanyForm{
  margin: 0 auto;
}

#addCompanyForm button{
  width: 350px !important;
}

.mailBox{
  border: solid 1px #3EBDA8;
  padding: 7px;
  text-align: center;
  margin: 5px;
  font-size: 12px;
  padding-top: 20px;
  padding-bottom: 20px;

}

.mailBox input{
  font-size: 12px;
 
}

.emailForm{
  background-color: #3ebda81a;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

.emailForm input{
  width:70px !important;
}

.addBox {
  margin:0!important;
}

i .icon{
float: left !important;

 
}

.closeBtn{
  font-size: 25px;
  font-weight: bolder;
  position: absolute;
  text-align: end;
  background-color: transparent;
  border: none;
  right: 50px;
  cursor: pointer;
  color: #3EBDA8;
  
 
}

.iconButton{

  color: #257166;
  font-size: 40px;
  cursor: pointer;

}

.trashBtn {

  color: red;
  font-size: 40px;
  cursor: pointer;
}

.tagContainer{

  background-color: #3EBDA8;
  color: white;

  width: 31%;
  margin: 10px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  text-align: center;
 
  
  
}

.tagRow {
  display: flex;
  
  width: 100%;
  text-align: center !important;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
 
}

.tagRow h4{
  font-size: 23px;
}

.invoice_table{
  font-size: 12px;
  line-height: 1.1;
  width: 100%;
  margin:0;
 


  

  
}

.invoice_table td, .invoice_table tr{
  padding: 5px !important;
}

.tb_container{

   
  margin-bottom: 40px;
  display: none;
  


}

.displayTbl{
  display: block;
 
}

#canvasContainer canvas {
  align-items: center;
  margin: 0 auto !important;
}

#options{
  margin-bottom: 20px;;
}

#invoice_data_table input{
  
  border: 1px solid #282c3463;
  border-radius: 10px;
  background-color: #ffffff80;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 3px;

}

#myInputSearch{
  min-width: 300px;
  padding: 12px;
  border-radius: 10px;
  border-color: #54545498;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 5px;
  font-size: medium;
}

.DeleteIcon{
  color: red;
}

#invoiceTable th{

  font-size: 11px;
}
#invoiceTable td{
  font-size: 11px;
}

.css-dd2h8b-MuiAutocomplete-root, .MuiSvgIcon-root{
  margin: 0 !important;
}

#invoiceTable svg{
  color: #282c34 !important;
  
}

#autoComp>.MuiSvgIcon-root{
  padding: 0 !important;
  border: none !important;
}

.DynamicTable th{
  font-size: 11px;
}

.DynamicTable td{
  padding: 5px;
  min-height: auto;
  font-size: 12px;
}

.DynamicTable input{
  border: 1px solid rgba(211, 211, 211, 0.596);
  border-radius: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.DynamicTable table{
  table-layout:fixed;
  width:100%;
  overflow: hidden;
}

.btnDelete{
  color: red;
  background-color: transparent;
  border:none;
  justify-content: center;
}

.btnAdd{
  color: #2E8A7C;
  font-size: 16px !important;
  font-weight: bolder;
  background-color: transparent;
  border: none;

}

.DynamicTable select {

  border-color: #4d4d4d;
  padding: 5px;
  width: 80px;
}

#POSupplierName{
  width: 120px;
  padding: 5px;
}

#saveBtn, #updateBtn{
  width: 20%;
  margin-top: 20px;
  text-transform: uppercase;
  background-color: #2E8A7C;
  color: white;
  border: none;
  padding: 7px;
  font-size: small;
  letter-spacing: 1px;
  margin-inline: 10px;
  
}

.editableTable{
  background-color: #60cab822;
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 20px;
}

#lightGrey{
  color: rgb(123, 123, 123);
}

.displayTbl{
  display: block !important;
 
}

.hideTbl{
  display: none;
 
}

#searchBtn:disabled,
#searchBtn[disabled]{
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}
