.main {
    margin-top: 2em;
  }
  
  .center {
    justify-content: center;
    padding: 10px;
  }
  
  .ui.search input {
    width: 100%;
    border-radius: 0 !important;
  }
  
  .item {
    padding: 15px 0px !important;
  }
  
  i.icon {
    float: right;
    font-size: 20px;
    cursor: pointer;
  }

  .buttons{
    background-color: #257166!important;
    color: white!important;
    min-width: 50% !important;
    border: none;
  }

  .field label{
    color: #257166!important;
    font-weight: 500!important;
    font-size: 11px!important;
   /* text-align: start!important;
    margin-left: 5px !important;*/
  }

  .field input{
    width: 50%!important;
  }

 .ui.avatar.image{
  width: 1.5em !important;
  height: 1.5em !important;
}

#list_users{
  font-size: 16px !important;
}
#rowDiv{
  margin: 0 auto;
}

#companyBox, #userBox{
  background-color: #25716613;
  padding: 20PX;
  border-radius: 15px;
  display: block;
  width: 45%;
  float: left;
  margin-inline: 20px;
}

#userBoxAdmin{
  background-color: #25716613;
  padding: 20PX;
  border-radius: 15px;
  display: block;
  width: 80%;
  margin: 0 auto;
}

#addCompanyForm{
  width: 80%;
  margin:0 auto;
}

.editBtn{
  background-color: transparent !important;
  border: none;
}